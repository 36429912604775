<template>
  <div class="shared-page" v-if="!isLoading">
    <expired-shared-page v-if="isExpired"/>
    <component v-else :is="componentsByType[shareType]"/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import TransactionStorySharedPage
  from '@/pages/responsive-pages/authenticated/navigation-pages/transaction-stories/TransactionStorySharedPage';
import sharePageConsts from '@/pages/responsive-pages/non-authenticated/share/sharePageConsts';
import StorySharedPage from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/StorySharedPage';
import ExpiredSharedPage from './ExpiredSharedPage';

export default {
  name: 'SharedPage',
  data() {
    return {
      isLoading: false,
    };
  },
  props: {
    sharePageId: {
      type: String,
      require: true,
    },
  },
  async created() {
    this.isLoading = true;
    await this.init(this.sharePageId);
    Segment.trackUserGot('SharePageEntered', this.analyticsData);
    await this.sendSharerAnalytics('Sharer_SharePageEntered');
    this.isLoading = false;
  },
  components: {
    RiseupButton: BaseUI.RiseupButton,
    ExpiredSharedPage,
  },
  computed: {
    ...mapState('sharePage', ['shareType', 'sharerCustomerId', 'sharerMemberId', 'isLoggedInCustomer', 'isExpired']),
    ...mapGetters('sharePage', ['analyticsData']),
    componentsByType() {
      return {
        [sharePageConsts.SHARE_TYPES.TRANSACTION_STORY]: TransactionStorySharedPage,
        [sharePageConsts.SHARE_TYPES.STORY]: StorySharedPage,
      };
    },
    analyticsMethod() {
      if (this.isLoggedInCustomer) {
        return this.sendAnalyticsLoggedIn;
      }
      return this.sendAnalytics;
    },
  },
  methods: {
    ...mapActions('sharePage', ['init', 'sendAnalytics', 'sendSharerAnalytics']),
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../../scss/mixins';

.shared-page {
  @include white-page-frame;
  height: 100%;
}
</style>
