<template>
  <span class="delayed-animated-number" :class="{ blurred }">
    <animated-number :value="animatedValue" :formatter="format"/>
  </span>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import money from '@/utils/money';

export default {
  name: 'BlurredAnimatedNumber',
  components: {
    AnimatedNumber: BaseUI.AnimatedNumber,
  },
  props: {
    delay: {
      required: true,
      type: Number,
    },
    startTimout: {
      required: true,
      type: Boolean,
    },
    value: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      blurred: true,
      animatedValue: 0,
    };
  },
  methods: {
    format(value) {
      return `${money.formatAmountWithFraction(value, 0)} ₪`;
    },
  },
  watch: {
    startTimout() {
      setTimeout(() => {
        this.blurred = false;
        this.animatedValue = this.value;
        this.$emit('timeout-finished', this.value);
      },
      this.delay);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../scss/mixins';

.delayed-animated-number {
  &.blurred {
    filter: blur(4px);
  }
}
</style>
