<template>
  <div class="transaction-story-shared-page">
    <div class="blur-overlay" v-if="isBlurredContent">
      <div class="title-prefix">{{ titlePrefix }}?</div>
      <div class="title">{{ shareData.title }}</div>
      <div>
        <riseup-button class="reveal-button" :size="'slim'" :action="revealContent" :title="'אני רוצה לראות'"/>
      </div>
    </div>
    <div class="content" :class="{ blurred: isBlurredContent, withBottomSheet: showBottomSheet }">
      <transaction-story-asset class="asset-wrapper" @finished-revealing-amounts="handleFinishedRevealingAmounts" :is-animated="true"
                               :is-blurred-content="isBlurredContent" :full-screen="true" :title="shareData.title"
                               :story-transactions="shareData.transactions" :title-prefix="titlePrefix" :template-class="storySettings.class"/>
      <shared-page-bottom-sheet class="bottom-sheet" :title="bottomSheetTitle" :subtitle="bottomSheetSubtitle" :show-bottom-sheet="showBottomSheet"/>
    </div>
  </div>
</template>

<script>
import TransactionStoryAsset
  from '@/pages/responsive-pages/authenticated/navigation-pages/transaction-stories/TransactionStoryAsset';
import BaseUI from '@riseupil/base-ui';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import SharedPageBottomSheet from '@/pages/responsive-pages/non-authenticated/share/SharedPageBottomSheet';
import Segment from '@/Segment';
import consts from '@/constants/transaction-stories-consts';

export default {
  name: 'TransactionStorySharedPage',
  data() {
    return {
      isBlurredContent: true,
      showBottomSheet: false,
    };
  },
  components: {
    TransactionStoryAsset,
    SharedPageBottomSheet,
    RiseupButton: BaseUI.RiseupButton,
  },
  computed: {
    ...mapState('sharePage', ['shareData', 'isLoggedInCustomer', 'sharePageId']),
    ...mapGetters('sharePage', ['analyticsData']),
    ...mapState('transactionStories', ['title']),
    title() {
      return this.shareData.title;
    },
    storySettings() {
      return consts.getStorySettingsWithDefault(this.title);
    },
    titlePrefix() {
      const { variant } = this.storySettings;
      return consts.TITLE_PREFIXES[variant];
    },
    bottomSheetTitle() {
      return 'רוצה לראות את ההוצאות שלך?';
    },
    bottomSheetSubtitle() {
      if (this.isLoggedInCustomer) {
        return 'אפשר לסכם הוצאות דרך ״כמה עלה לי״ בתפריט שבתזרים';
      }
      return 'זה הזמן להצטרף לרייזאפ, חודש ראשון מתנה!';
    },
  },
  methods: {
    ...mapActions('transactionStories', ['setSelectedTransactions']),
    ...mapMutations('transactionStories', ['setTitle']),
    revealContent() {
      Segment.trackUserInteraction('SharePageRevealClicked', this.analyticsData);
      this.isBlurredContent = false;
    },
    handleFinishedRevealingAmounts() {
      setTimeout(() => {
        this.showBottomSheet = true;
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../../../scss/mixins';

.transaction-story-shared-page {
  height: 100%;
  width: 100%;
  position: relative;
  .blur-overlay {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 44.88%, rgba(255, 255, 255, 0) 100%);
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: SimplerPro;
    .title-prefix {
      font-size: 18px;
      color: $riseup_blue;
      margin-top: 120px;
      margin-bottom: 20px;
    }
    .title {
      font-weight: bold;
      font-size: 32px;
      color: $riseup_black;
      margin-bottom: 56px;
    }
    .reveal-button {
      width: 260px;
    }
  }

  .content {
    position: relative;
    @include white-page-frame;
    height: 100%;
    display: flex;
    justify-content: space-between;
    &.withBottomSheet {
      padding-bottom: 98px;
    }
    &.blurred {
      filter: blur(4px);
    }
    .asset-wrapper {
      width: 100%;
      height: 100%;
      flex-grow: 1;
      overflow: hidden;
    }
    .bottom-sheet {
      z-index: 10;
      position: absolute;
      bottom: 0;
    }
  }
}
</style>
