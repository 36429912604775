<template>
  <div class="story-viewer">
    <div class="navigator" :class="{ hidden: holding }">
      <div v-for="(page, index) of pages"
           :key="index"
           :class="{ seen: index < currentPageIndex }"
           class="page-bar">
        <div v-if="index === currentPageIndex" class="page-bar-overlay" ref="pageBars" :style="barStyle"></div>
      </div>
    </div>
    <div class="content">
      <div v-if="showCloseButton" class="close-action" :class="{ hidden: holding }" v-on:click="closeStory">
        <img :src="require(`@/assets/icons/close-white.svg`)"/>
      </div>
      <div
        v-if="currentPageVideo && currentPageVideo.getAttribute('data-show-mute-button')"
        class="mute-button"
        :class="{ hidden: holding }"
        @click="toggleMute">
        <img v-show="!videosMuted" :src="require('@/assets/mid-month/mute.svg')" alt="mute">
        <img v-show="videosMuted" :src="require('@/assets/mid-month/unmute.svg')" alt="unmute">
      </div>
      <div class="page-content" ref="pageContent"
           v-on:click="screenClick" v-touch:start="touchStart" v-touch:end="touchEnd" v-touch:touchhold="touchHold">
        <div class="story-done" v-show="storyDone">
          <div class="watch-again" v-on:click.stop="startOver">
            <img :src="require('@/assets/mid-month/restart.svg')">
            <div>לנגן מחדש</div>
          </div>
        </div>
        <page-viewer class="page" v-for="(page, index) in pages" :key="index" v-show="index === currentPageIndex"
                     :pageId="index" :page-content="page.content" v-on:next="nextPage" v-on:prev="prevPage"
                     @videoLoaded="videoLoaded" @userInteraction="onUserInteraction">
        </page-viewer>
      </div>
      <div class="footer" :class="{ hidden: holding }" v-if="!currentPageConfig.hideFooter">
        <div class="right">
          <img alt="right" :src="require('@/assets/mid-month/right.svg')" v-on:click="prevPage" v-if="!isFirstPage" />
        </div>
        <template v-if="currentComponentShare">
          <div v-if="!isViewer">
            <share-button :share-name="shareType" :share-data="shareData"
                          v-on:click.stop :share-type="shareType" :share-component="shareComponent" :share-component-props="shareComponentProps"
                          :show-popup="false" :share-text="shareText" :segment-extra-props="segmentProps" v-slot="slotProps">
              <tiny-loader v-if="slotProps.loading" class="loader"/>
              <div v-else class="share-button-wrapper" v-on:click="() => shareClicked(slotProps.onClick)">
                <img class="share-image" :src="require('@/assets/Share button.svg')">
                <span class="share-button">לשתף</span>
              </div>
            </share-button>
          </div>
          <template v-else>
            <div class="join-wrapper">
              <viewer-signup-or-redirect v-slot="slotProps" :router-path="storyPath" :segment-extra-props="viewerSegmentProps">
                <span class="join-button" v-on:click="slotProps.onClick">לסיפור שלי</span>
              </viewer-signup-or-redirect>
            </div>
          </template>
        </template>
        <div class="left">
          <img alt="left" :src="require('@/assets/mid-month/left.svg')" v-on:click="nextPage" v-if="!isLastPage"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import router from '@/router';
import Intercom from '@/Intercom';
import Segment from '@/Segment';
import ShareButton from '@/base-components/share/ShareButton';
import storyLib from '@riseupil/story-lib';
import BaseUI from '@riseupil/base-ui';
import * as _ from 'lodash';
import Vue from 'vue';
import PageViewerAsset from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/PageViewerAsset';
import ShareEoy
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/widgets/custom-widgets/ShareEoy';
import { mapActions, mapGetters } from 'vuex';
import SignupOrRedirect
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/widgets/custom-widgets/SignupOrRedirect';
import sharePageConsts from '@/pages/responsive-pages/non-authenticated/share/sharePageConsts';
import ViewerSignupOrRedirect
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/ViewerSignupOrRedirect';
import ShareEoc
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/widgets/custom-widgets/ShareEoc';

const TIME_PER_PAGE_MS = 7000;
Vue.component('ShareEoc', ShareEoc);
Vue.component('ShareEoy', ShareEoy);
Vue.component('SignupOrRedirect', SignupOrRedirect);

export default {
  name: 'StoryViewer',
  components: {
    ShareButton,
    PageViewer: storyLib.PageViewer,
    ViewerSignupOrRedirect,
    TinyLoader: BaseUI.TinyLoader,
  },
  props: {
    pages: {
      type: Array,
      required: true,
    },
    storyName: {
      type: String,
      required: true,
    },
    isViewer: {
      type: Boolean,
      default: false,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentPageIndex: 0,
      storyDone: false,
      timePassed: 0,
      lastClick: 0,
      holding: false,
      nextPageTimeout: null,
      debug: false,
      videos: {},
      videosMuted: true,
    };
  },
  created() {
    if (this.$route.query.debug) {
      this.debug = true;
    }
    if (this.$route.query.page) {
      this.currentPageIndex = _.parseInt(this.$route.query.page);
    }
    this.startTimer();
    window.addEventListener('keyup', this.onKeyUp);
    Segment.trackUserGot('StoryStarted', this.segmentProps);
  },
  mounted() {
    Intercom.hideLauncher();
    Intercom.hide();
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.onKeyUp);
    Intercom.showLauncher();
  },
  computed: {
    ...mapGetters('featureFlags', ['saveStoryShare']),
    ...mapGetters('story', ['singleFinishViewerPages']),
    segmentProps() {
      return {
        storyName: this.storyName,
        pageName: this.pages[this.currentPageIndex].name,
      };
    },
    viewerSegmentProps() {
      return { ...this.segmentProps, isSinglePageShare: this.pages.length <= 2, isFooterButton: true };
    },
    currentPage() {
      return this.pages[this.currentPageIndex];
    },
    currentComponentShare() {
      return this.currentPage.canShare ? {
        filename: `${this.storyName}-${this.currentPageIndex + 1}`,
        component: PageViewerAsset,
        props: {
          pageContent: this.currentPage.content,
        },
      } : null;
    },
    isLastPage() {
      return this.currentPageIndex === this.pages.length - 1;
    },
    isFirstPage() {
      return this.currentPageIndex === 0;
    },
    barAnimationPlayState() {
      if (this.nextPageTimeout) {
        return 'running';
      }
      return 'paused';
    },
    barStyle() {
      return {
        'animation-play-state': this.barAnimationPlayState,
        'animation-duration': `${this.currentPageTime}ms`,
      };
    },
    currentPageConfig() {
      return this.currentPage.config || {};
    },
    currentPageTime() {
      const videoDuration = this.currentPageVideo ? this.currentPageVideo.duration * 1000 : null;
      return videoDuration || this.currentPageConfig.time || TIME_PER_PAGE_MS;
    },
    currentPageVideo() {
      return this.videos[this.currentPageIndex];
    },
    currentPageHasVideo() {
      return _.some(this.currentPage.content.widgets, w => w.type === 'video');
    },
    shareType() {
      return sharePageConsts.SHARE_TYPES.STORY;
    },
    shareText() {
      return 'הנה הצצה לחלק מסיכום השנה שלי ברייזאפ';
    },
    shareComponent() {
      return PageViewerAsset;
    },
    shareData() {
      return { name: this.storyName, pages: [this.currentPage, ...this.singleFinishViewerPages] };
    },
    shareComponentProps() {
      return { pageContent: this.currentPage.content };
    },
    storyPath() {
      return `/st/${this.storyName}`;
    },
  },
  methods: {
    ...mapActions('sharePage', ['createSharePage']),
    onUserInteraction(event) {
      Segment.trackUserInteraction(event.eventName, { ...this.segmentProps, ...event });
    },
    async closeStory() {
      Segment.trackUserInteraction('StoryClosed', this.segmentProps);
      if (router.canGoBack(this.$route)) {
        router.go(-1);
      } else {
        await router.push({ path: '/sr' });
      }
    },
    startOver() {
      Segment.trackUserInteraction('StoryStartOverClicked', this.segmentProps);
      this.currentPageIndex = 0;
      this.startTimer();

      // On stories with one page we also need to restart the animation since we don't really move to a different bar
      if (this.pages.length === 1) {
        this.restartAnimation();
      }
    },
    nextPage() {
      // We have race conditions with the intercom launcher appearing and we don't understand how to prevent it
      // As a fail-safe we're just going to hide it every single time we move page.
      Intercom.hideLauncher();
      Intercom.hide();
      if (this.isLastPage) {
        this.stopScroll();
        this.setStoryDone();
        this.finishAnimation();
      } else {
        this.currentPageIndex += 1;
        this.startTimer();
      }
    },
    prevPage() {
      if (this.isFirstPage || this.storyDone) {
        // On first page - restart the page
        // On story done - don't go back a page, just restart the last page
        this.restartAnimation();
      } else {
        this.currentPageIndex -= 1;
      }
      this.startTimer();
    },
    startTimer() {
      // If this page has a video and the video hasn't been loaded yet, we don't start the story
      if (this.currentPageHasVideo && !this.currentPageVideo) {
        return;
      }
      this.stopScroll();
      this.timePassed = 0;
      this.storyDone = false;
      this.resetAllVideos();
      this.resumeScroll();
    },
    stopScroll() {
      clearTimeout(this.nextPageTimeout);
      this.pauseAllVideos();
      this.nextPageTimeout = null;
      const now = +new Date();
      this.timePassed += now - this.lastClick;
    },
    resumeScroll() {
      this.lastClick = +new Date();
      const remainingTime = this.currentPageTime - this.timePassed;
      this.playCurrentVideo();
      this.nextPageTimeout = setTimeout(() => {
        // In order to be able to develop this feature, we can add the debug=1 to the query params
        if (!this.debug) {
          this.nextPage();
        }
      }, remainingTime);
    },
    touchStart() {
      this.stopScroll();
    },
    touchEnd() {
      this.resumeScroll();

      // Since the click event happens only after the touch end happens, we want to make sure we free the holding only after we get the click
      // We're using setTimeout with 0 to make sure the clicked happens before we release the holding
      setTimeout(() => {
        this.holding = false;
      }, 0);
    },
    screenClick(event) {
      // Why are we using a click event here and not just running this logic when touchEnd finishes?
      // The reason is that we want to enable pages to support clicks and override the story viewer click handler.
      // For example if a page wants to run a poll and has a button in it, it will be able to catch the click and stop propagation
      // to prevent the story viewer to move to the next/prev page
      // While the page would like to disable the click that moves between pages - we still want to support the 'hold' function
      // meaning that the user should be able to hold the screen - so we still want to get the touchEnd event to release the hold

      // The click will happen even if we're holding the screen - on the release, we don't want every release to trigger
      // a next/prev page logic, so we'll check if we're currently holding the screen
      if (!this.holding) {
        const x = event.clientX - this.$refs.pageContent.getBoundingClientRect().x;
        if (x < (this.$refs.pageContent.clientWidth / 3)) {
          Segment.trackUserInteraction('StoryNextPageClicked', this.segmentProps);
          this.nextPage();
        } else {
          Segment.trackUserInteraction('StoryPrevPageClicked', this.segmentProps);
          this.prevPage();
        }
      }
    },
    touchHold() {
      Segment.trackUserInteraction('StoryHold', this.segmentProps);
      this.holding = true;
    },
    onKeyUp(e) {
      switch (e.keyCode) {
      case 37:
        this.nextPage();
        break;
      case 39:
        this.prevPage();
        break;
      default: break;
      }
    },
    restartAnimation() {
      // This restart animation method was taken from this video: https://youtu.be/ut5alGmdFNc
      const bar = this.$refs.pageBars[0];
      bar.style.animationName = 'none';
      requestAnimationFrame(() => {
        bar.style.animationName = '';
      });
    },
    setStoryDone() {
      Segment.trackUserGot('StoryEnded', this.segmentProps);
      this.storyDone = true;
    },
    finishAnimation() {
      const bar = this.$refs.pageBars[0];
      bar.style.animationName = 'none';
    },
    async shareClicked(onClick) {
      this.stopScroll();
      await onClick();
      this.resumeScroll();
    },
    videoLoaded({ ref, pageId }) {
      Vue.set(this.videos, pageId, ref);

      // If we're in the current page, then it is waiting for the video to load in order to start the video and the animation
      if (this.currentPageIndex === pageId) {
        this.startTimer();
      }
    },
    pauseAllVideos() {
      _.forEach(this.videos, video => video.pause());
    },
    resetAllVideos() {
      _.forEach(this.videos, video => {
        // eslint-disable-next-line
        video.currentTime = 0;
      });
    },
    playCurrentVideo() {
      if (this.currentPageVideo) {
        this.currentPageVideo.play();
      }
    },
    toggleMute() {
      this.videosMuted = !this.videosMuted;
      Segment.trackUserInteraction('StoryMute', { ...this.segmentProps, toMute: this.videosMuted });
      _.forEach(this.videos, video => {
        // eslint-disable-next-line
        video.muted = this.videosMuted;
      });
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

@keyframes bar {
  0%   { width: 0; }
  100% { width: 100% }
}

.story-viewer {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $riseup_black;
  font-family: SimplerPro;
  user-select: none;

  .navigator {
    position: absolute;
    width: 100%;
    z-index: 3;
    display: flex;
    justify-content: space-evenly;
    direction: rtl;
    padding: 12px;
    opacity: 1;
    transition: opacity 0.2s linear;

    &.hidden {
      opacity: 0;
    }

    .page-bar {
      margin: 0 3px;
      width: 100%;
      height: 2px;
      background-color: $riseup_gray_1;
      overflow: hidden;

      &.seen {
        background-color: $riseup_white;
      }

      .page-bar-overlay {
        height: 2px;
        background-color: $riseup_white;
        animation-name: bar;
        animation-timing-function: linear;
      }
    }
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;

    .close-action {
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      padding: 10px;
      margin-top: 16px;
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.2s linear;

      &.hidden {
        opacity: 0;
      }
    }

    .mute-button {
      position: absolute;
      z-index: 2;
      right: 0;
      bottom: 90px;
      padding: 10px;
      margin-right: 12px;
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.2s linear;

      &.hidden {
        opacity: 0;
      }
    }

    .page-content {
      flex-grow: 1;
      position: relative;

      .story-done {
        position: absolute;
        z-index: 99999999;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 100%;
        width: 100%;
        background: RGBA(0, 0, 0, 0.6);

        .watch-again {
          display: flex;
          cursor: pointer;
          font-family: SimplerPro;
          font-weight: bold;
          border-radius: 20px;
          font-size: 20px;
          color: $riseup-white;
          padding: 12px 20px;
          background: RGBA(0, 0, 0, 0.4);

          img {
            margin-left: 10px;
          }
        }
      }

      .page {
        ::v-deep img {
          // This is to prevent dragging images and interrupting the natural hold button flow
          // If a page needs to be interactive it can alter the pointer events itself
          pointer-events: none;
        }
      }
    }
  }

  .footer {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 22px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(0deg, rgba(65, 65, 65, 0.7) 0%, rgba(229, 229, 229, 0) 100%);
    opacity: 1;
    transition: opacity 0.2s linear;

    &.hidden {
      opacity: 0;
    }

    .share-button-wrapper {
      display: flex;
      align-items: center;
      cursor: pointer;

      .share-image {
        height: 36px;
        width: 36px;
        margin-left: 12px;
      }

      .share-button {
        color: $riseup_white;
        font-size: 18px;
        font-family: SimplerPro;
      }
    }

    .join-wrapper {
      display: flex;
      align-items: center;
      cursor: pointer;

      .join-button {
        color: $riseup_white;
        font-size: 18px;
        padding: 8px 16px;
        font-family: SimplerPro;
        border: 1px solid $riseup_white;
        border-radius: 36px;
      }
    }

    .right, .left {
      display: flex;
      align-content: center;
      justify-content: space-between;
      width: 39px;
      img {
        box-sizing: content-box;
        width: 11px;
        height: 18px;
        padding: 14px;
        cursor: pointer;
      }
    }
    .loader {
      border: 4px solid $riseup_white;
      border-top: 4px solid transparent;
    }
  }

  // Don't show the move buttons on mobile
  @media (max-width: 430px) {
    .footer {
      justify-content: space-around;
      .right, .left {
        display: none;
      }
    }
  }
}
</style>
