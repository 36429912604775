<template>
  <div class="expired-shared-page">
    <riseup-header/>
    <div class="hourglass">⌛️</div>
    <div class="message">פג תוקף העמוד שהגעת אליו</div>
  </div>
</template>

<script>
import RiseupHeader from '@/base-components/app-header/RiseupHeader';

export default {
  name: 'ExpiredSharedPage',
  props: {
    sharePageId: {
      type: String,
      require: true,
    },
  },
  components: {
    RiseupHeader,
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../../scss/mixins';

.expired-shared-page {
  @include white-page-frame;
  height: 100%;
  .hourglass {
    margin-top: 90px;
    font-size: 84px;
  }
  .message {
    margin-top: 24px;
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
