<template>
  <div class="transaction-group" :class="{ empty: transactions.length === 0 }">
    <div class="group-date" @click="toggleOpen">
      <div class="date-name">{{ name }}</div>
      <img class="arrow" :class="{ open: open }" :src="require('@/assets/icons/arrow-down.svg')">
    </div>
    <collapse-transition>
      <div class="group-transactions" v-if="open">
        <div class="transaction" v-for="transaction of sortedTransactions" :key="transaction.transactionId"
             @click="toggleSelection(transaction.transactionId)">
          <div class="checkbox" :class="{ selected: transaction.selected }">
            <img class="checkmark" :class="{ selected: transaction.selected }" :src="require('@/assets/icons/checkmark-blue-little.svg')">
          </div>
          <div class="date">{{ formatDate(transaction) }}</div>
          <div class="name">{{ transaction.businessName }}</div>
          <div class="amount" :class="{ selected: transaction.selected }">{{ formatAmountWithFractionAndCurrency(transaction.billingAmount) }}</div>
        </div>
      </div>
    </collapse-transition>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import dateUtils from '@/utils/dates';
import TransactionDisplayUtils from '../cashflow-app/cashflow-view/generic-components/TransactionDisplayUtils';

export default {
  name: 'TransactionGroup',
  mixins: [TransactionDisplayUtils],
  components: {
    CollapseTransition: BaseUI.CollapseTransition,
  },
  props: {
    transactions: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    initialOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      open: this.initialOpen,
    };
  },
  computed: {
    sortedTransactions() {
      return _.orderBy(this.transactions, ['transactionDate', 'businessName'], ['desc', 'asc']);
    },
  },
  methods: {
    ...mapActions('transactionStories', ['toggleSelection']),
    toggleOpen() {
      // eslint-disable-next-line no-param-reassign
      this.open = !this.open;
    },
    formatDate(transaction) {
      return dateUtils.shortDateFormat(transaction.transactionDate);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.transaction-group {
  border-top: 1px solid $riseup_gray_0;
  cursor: pointer;
  &.empty {
    cursor: default;
    color: $riseup_gray_0;
    .arrow {
      display: none;
    }
  }
  .group-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 16px 18px 25px;
    background-color: $riseup_white;
    font-size: 14px;
    font-weight: bold;
    .arrow {
      width: 13px;
      transition: all 0.3s;
      &.open {
        transform: rotate(180deg);
      }
    }
  }
  .transaction {
    padding: 15px;
    display: flex;
    align-items: center;
    background-color: $riseup_gray_disabled;
    border-bottom: 1px solid $riseup_gray_0;
    font-size: 12px;
    .checkbox {
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      border-radius: 4px;
      border: 1px solid $riseup_black;
      background-color: $riseup_white;
      .checkmark {
        height: 12px;
        margin-right: 1px;
        margin-top: 3px;
        &:not(.selected) {
          display: none;
        }
      }
      &.selected {
        border-color: $riseup_blue;
      }
    }
    .date {
      width: 60px;
      flex-shrink: 0;
      margin-right: 10px;
    }
    .name {
      flex-grow: 1;
      padding-left: 10px;
      overflow:hidden;
      white-space:nowrap;
      text-overflow: ellipsis;
    }
    .amount {
      width: 60px;
      flex-shrink: 0;
      &.selected {
        font-weight: bold;
        color: $riseup_blue;
      }
    }
  }
}
</style>
