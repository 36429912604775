<template>
  <div class="share-eoc">
    <riseup-button class="share-button" title="שיתוף עם חברים" :variant="'secondary'" :action="onClick"/>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import shareUtils from '@/utils/share';
import Segment from '@/Segment';

export default {
  name: 'ShareEoc',
  components: {
    RiseupButton: BaseUI.RiseupButton,
  },
  methods: {
    async onClick() {
      Segment.trackUserGot('EocShareClicked', {});
      await shareUtils.shareLink('https://input.riseup.co.il/shared/45418691-6083-4c41-a1b5-9fffaf62ebff', 'היי, חשבתי שזה יעניין אותך!', {});
    },
  },
};
</script>

<style lang="scss" scoped>
.share-eoc {
  position: absolute;
  display: flex;
  .share-button {
    width: 100%;
    height: 100%;
    border-radius: 154px;
  }
}
</style>
