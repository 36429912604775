<template>
  <div class="transaction-story-asset" :class="[templateClass, { fullScreen }]">
    <div class="frame">
      <transaction-story-core :is-animated="isAnimated" @finished-revealing-amounts="handleFinishedRevealingAmounts" :title-prefix="titlePrefix"
                              :startAnimation="!isBlurredContent" :title="title" :story-transactions="storyTransactions"/>
    </div>
  </div>
</template>

<script>

import TransactionStoryCore
  from '@/pages/responsive-pages/authenticated/navigation-pages/transaction-stories/TransactionStoryCore';

export default {
  name: 'TransactionStoryAsset',
  props: {
    isBlurredContent: {
      type: Boolean,
      default: false,
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
    isAnimated: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    titlePrefix: {
      type: String,
    },
    storyTransactions: {
      type: Array,
    },
    templateClass: {
      type: String,
    },
  },
  components: {
    TransactionStoryCore,
  },
  methods: {
    handleFinishedRevealingAmounts() {
      this.$emit('finished-revealing-amounts');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../../../scss/stories-templates.scss';
@import '../../../../../scss/mixins';

.transaction-story-asset {
  display: flex;
  flex-direction: column;
  width: 500px;
  @include story-templates('../../../../../assets');
  background-size: 100%;
  &.fullScreen {
    height: 100%;
    width: 100%;
  }
  .frame {
    max-height: 100%;
    padding: 24px;
  }
}
</style>
