<template>
  <div class="share-link-modal" ref="shareModal">
    <full-screen-modal :close-action="closeActionClicked">
      <template v-slot:content>
        <div class="share-content">
          <component :is="shareComponent" v-bind="{ ...shareComponentProps, fullScreen: true }" class="shared-asset" ref="shareAsset"/>
          <div class="buttons-wrapper">
            <share-button class="modal-button" :is-share-link="true" :filename="filename" :share-name="shareType" :share-data="shareData"
                          :share-type="shareType" :share-component="shareComponent" :share-component-props="shareComponentProps" :show-popup="false"
                          :segment-extra-props="segmentData" :share-text="shareText" v-slot="slotProps">
              <riseup-button class="modal-button"
                             :action="slotProps.onClick"
                             :title="'לשתף עם חברים'"
                             :variant="'primary'"
                             :disabled="isSharing"
                             :icon="require('@/assets/share-popup/share.svg')"
                             :size="'slim'"
                             :loading="slotProps.loading"/>
            </share-button>
            <share-button class="modal-button" :force-download="true" :filename="filename" :share-name="shareType"
                          :share-component="shareComponent" :share-component-props="shareComponentProps" :show-popup="false"
                          :segment-extra-props="segmentData" :share-impression="shareImpressionOptions.NEVER" v-slot="slotProps">
              <riseup-button :action="slotProps.onClick"
                             :title="'להוריד למכשיר'"
                             :variant="'secondary'"
                             :disabled="isSharing"
                             :icon="require('@/assets/share-popup/download.svg')"
                             :size="'slim'"
                             :loading="slotProps.loading"/>
            </share-button>
          </div>
        </div>
      </template>
    </full-screen-modal>
  </div>
</template>

<script>

import BaseUI from '@riseupil/base-ui';
import { mapActions } from 'vuex';
import shareEvents from '@/utils/shareEvents';
import shareUtils from '@/utils/share';
import FullScreenModal from '@/base-components/FullScreenModal';
import ShareButton from '@/base-components/share/ShareButton';

export default {
  name: 'shareLinkModal',
  components: {
    FullScreenModal,
    RiseupButton: BaseUI.RiseupButton,
    ShareButton,
  },
  props: {
    shareText: {
      type: String,
      required: false,
    },
    shareType: {
      type: String,
      required: true,
    },
    shareComponent: {
      type: Object,
      required: true,
    },
    shareData: {
      type: Object,
    },
    shareComponentProps: {
      type: Object,
    },
    filename: {
      type: String,
      required: true,
    },
    segmentData: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    },
  },
  data() {
    return {
      isSharing: false,
      shareAssetPromise: null,
    };
  },
  created() {
    shareEvents.trackSharePopupShown(this.segmentData);
  },
  computed: {
    shareImpressionOptions() {
      return shareUtils.SHARE_IMPRESSION_OPTIONS;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    closeActionClicked() {
      shareEvents.trackSharePopupClosed(this.segmentData);
      this.closeModal();
    },
  },
};

</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../scss/mixins';

.share-link-modal {
  height: 100%;
  .share-content {
    display: flex;
    justify-content: space-between;
    @include white-page-frame;
    height: 100%;
    .shared-asset {
      flex-grow: 1;
      overflow: hidden;
    }
    .buttons-wrapper {
      bottom: 0;
      .modal-button {
        width: 100%;
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

</style>
