<template>
  <colored-popup class="select-title-popup warning" :close-action="close">
    <template v-slot:top-content>
      <div class="top-content">אז כמה עלה?</div>
    </template>

    <template v-slot:bottom-content>
      <div class="selections">
        <radio-select :options="radioOptions" :set-answer="selectTitle" :start-selection="title" :allow-other-select="true"/>
        <riseup-button :action="saveSelection"
                       title="אישור"
                       size="slim"
                       :disabled="isDisabled"/>
      </div>
    </template>
  </colored-popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import _ from 'lodash';
import { mapActions, mapState } from 'vuex';
import Segment from '@/Segment';
import RadioSelect from '@/base-components/RadioSelect';
import Intercom from '@/Intercom';
import consts from '@/constants/transaction-stories-consts';

export default {
  name: 'SelectTitlePopup',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
    RadioSelect,
  },
  created() {
    Segment.trackUserGot('SelectStoryTitlePopupShown');
    this.selectedTitle = this.title;
  },
  data() {
    return {
      selectedTitle: '',
    };
  },
  computed: {
    ...mapState('transactionStories', ['title']),
    radioOptions() {
      return _.chain(consts.STORY_SETTINGS)
        .values()
        .map(({ label }) => { return { value: label, label }; })
        .value();
    },
    isDisabled() {
      return !this.selectedTitle;
    },
  },
  methods: {
    ...mapActions('transactionStories', ['setTitle']),
    close() {
      Segment.trackUserInteraction('SelectStoryTitlePopupClosed');
      this._close();
    },
    selectTitle(title) {
      this.selectedTitle = title;
    },
    saveSelection() {
      Segment.trackUserInteraction('SelectStoryTitlePopupDone', { title: this.selectedTitle });
      this.setTitle(this.selectedTitle);
      this._close();
    },
    _close() {
      this.$emit('close');
      // When a modal disappears it automatically returns the launcher so we want to hide it
      Intercom.hideLauncher();
    },
  },
};
</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.select-title-popup {
  text-align: right;

  .top-content {
    font-size: 22px;
    font-weight: bold;
  }
}
</style>
