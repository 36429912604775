<template>
  <div class="transaction-stories" v-if="!contentLoading">
    <div class="page-title">כמה עלה לי?</div>
    <div class="page">
      <card class="card" :has-content-padding="false">
        <div class="title-selector">
          <div class="selected-title" @click="openTitleSelection">
            <div>{{ title }}</div>
            <div class="arrow">
              <img :src="require('@/assets/icons/arrow-left.svg')"/>
            </div>
          </div>
        </div>
        <div class="asset" :class="templateClass">
          <transaction-story-core :title="title" :title-prefix="titlePrefix" :story-transactions="storyTransactions"/>
        </div>
        <div class="transactions-selector">
          <transaction-group v-for="(group, index) of transactionGroups" :key="group.dateName"
                             :transactions="group.transactions" :name="group.dateName" :initial-open="index === 0"/>
        </div>
      </card>
    </div>
    <div class="share-button-container">
        <riseup-button class="share-button"
                       @click="openShareLinkModal"
                       :title="'סיימתי'"
                       :disabled="isDisabled"/>
    </div>
  </div>
</template>

<script>
import Segment from '@/Segment';
import moment from 'moment';
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import { mapActions, mapGetters, mapState } from 'vuex';
import TransactionDisplayUtils
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/TransactionDisplayUtils';
import dateUtils from '@/utils/dates';
import Intercom from '@/Intercom';
import sharePageConsts from '@/pages/responsive-pages/non-authenticated/share/sharePageConsts';
import ShareLinkModal from '@/base-components/share/ShareLinkModal';
import TransactionStoryAsset
  from '@/pages/responsive-pages/authenticated/navigation-pages/transaction-stories/TransactionStoryAsset';
import consts from '@/constants/transaction-stories-consts';
import SelectTitlePopup from './SelectTitlePopup';
import TransactionGroup from './TransactionGroup';
import TransactionStoryCore from './TransactionStoryCore';

export default {
  name: 'TransactionStories',
  mixins: [TransactionDisplayUtils],
  components: {
    TransactionGroup,
    TransactionStoryCore,
    RiseupButton: BaseUI.RiseupButton,
    Card: BaseUI.Card,
  },
  async created() {
    Intercom.hideLauncher();
    this.startContentLoading(this.$options.name);
    await this.initTransactions();
    this.doneContentLoading(this.$options.name);
    Segment.trackUserGot('TransactionStoriesEntered', {});
  },
  beforeDestroy() {
    Intercom.showLauncher();
  },
  computed: {
    ...mapState('transactionStories', ['title', 'selectableTransactions']),
    ...mapGetters('transactionStories', ['storyTransactions']),
    ...mapGetters('contentLoader', ['contentLoading']),
    shareData() {
      return {
        title: this.title,
        titlePrefix: this.titlePrefix,
        transactions: this.storyTransactions,
        templateClass: this.templateClass,
      };
    },
    shareComponentProps() {
      return {
        templateClass: this.templateClass,
        storyTransactions: this.storyTransactions,
        title: this.title,
        titlePrefix: this.titlePrefix,
      };
    },
    storySettings() {
      return consts.getStorySettingsWithDefault(this.title);
    },
    titlePrefix() {
      return consts.TITLE_PREFIXES[this.storySettings.variant];
    },
    shareText() {
      const start = 'רציתי לשתף אותך ב';
      const end = ', רוצה להעיף מבט?';
      return `${start}${this.titlePrefix} ${this.title}${end}`;
    },
    selectedSum() {
      return _.sumBy(this.storyTransactions, t => t.billingAmount);
    },
    templateClass() {
      return this.storySettings.class;
    },
    segmentData() {
      return {
        shareName: 'TransactionStory',
        title: this.title,
        transactions: this.storyTransactions.length,
        sumAmount: this.selectedSum,
      };
    },
    transactionGroups() {
      const groups = [
        { dateName: 'השבוע', startDate: moment().startOf('week') },
        { dateName: 'שבוע שעבר', startDate: moment().subtract(1, 'week').startOf('week') },
        this.createDateGroupForMonth(moment().startOf('month')),
        this.createDateGroupForMonth(moment().subtract(1, 'month').startOf('month')),
        this.createDateGroupForMonth(moment().subtract(2, 'month').startOf('month')),
        this.createDateGroupForMonth(moment().subtract(3, 'month').startOf('month')),
      ];
      return _.chain(groups)
        .reduce((state, currentGroup) => {
          const [groupTransactions, otherTransactions] = _.partition(state.transactions, t => (currentGroup.startDate <= t.transactionDate));
          return { transactions: otherTransactions, groups: [...state.groups, { ...currentGroup, transactions: groupTransactions }] };
        }, { groups: [], transactions: _.reject(this.selectableTransactions, _hasFutureTransactionDate()) })
        .thru(reducedStated => reducedStated.groups)
        .reject(group => group.transactions.length === 0)
        .value();
    },
    isDisabled() {
      return this.storyTransactions.length === 0;
    },
  },
  methods: {
    ...mapActions('contentLoader', ['startContentLoading', 'doneContentLoading']),
    ...mapActions('transactionStories', ['initTransactions']),
    ...mapActions('modalRootStore', ['openModal']),
    openShareLinkModal() {
      Segment.trackUserInteraction('TransactionStoriesFinishedClicked', { ...this.segmentData });
      this.openModal({
        component: ShareLinkModal,
        props: {
          shareType: sharePageConsts.SHARE_TYPES.TRANSACTION_STORY,
          shareComponent: TransactionStoryAsset,
          shareComponentProps: this.shareComponentProps,
          shareData: this.shareData,
          filename: 'story',
          segmentData: this.segmentData,
          shareText: this.shareText,
        },
        popupAlignment: 'full-screen',
      });
    },
    openTitleSelection() {
      this.openModal({ component: SelectTitlePopup });
    },
    createDateGroupForMonth(startDate) {
      return { dateName: dateUtils.getHebrewMonth(startDate.toDate()), startDate };
    },
  },
};

function _hasFutureTransactionDate() {
  const endOfToday = moment().endOf('day');
  return transaction => (transaction.transactionDate > endOfToday);
}
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/border-radius';
@import '@/scss/mixins';
@import '@/scss/stories-templates.scss';

.transaction-stories {
  .page-title {
    @include responsive-padding;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: $riseup_white;
    border-bottom: 1px solid $riseup_gray_0;
    font-size: 16px;
    font-weight: bold;
  }
  .page {
    padding: 30px;
    background-color: $riseup_gray_-1;
    .card {
      .asset {
        padding: 24px;
        @include story-templates('../../../../../assets');
        background-size: 100%;
      }
      .title-selector {
        padding: 24px;
        background-color: $riseup_white;
        border-radius: $card-border-radius $card-border-radius 0 0;
        .selected-title {
          padding: 19px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid $riseup-black;
          border-radius: 6px;
          font-size: 18px;
          cursor: pointer;
          .arrow {
            height: 16px;
            img {
              height: 16px;
              transform: rotate(-90deg);
            }
          }
        }
      }
    }
  }
  .share-button-container {
    position: sticky;
    bottom: 0;
    padding: 24px;
    background-color: $riseup_white;
  }
}
</style>
