<template>
  <story-viewer class="story-shared-page"
      v-if="!appLoading"
      :story-name="this.shareData.name"
      :pages="this.shareData.pages"
      :is-viewer="true"
      :show-close-button="false"
  ></story-viewer>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import StoryViewer from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/StoryViewer';

export default {
  name: 'StorySharedPage',
  components: {
    StoryViewer,
  },
  computed: {
    ...mapGetters('loader', ['appLoading']),
    ...mapState('sharePage', ['shareData']),
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../../../scss/mixins';
.story-shared-page {
  width: 100%;
}
</style>
