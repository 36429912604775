<template>
  <div class="transaction-story-core">
    <div class="top">
      <div class="text-wrapper">
        <div class="title-prefix">{{ titlePrefix }}</div>
        <div class="title">{{ title }}</div>
      </div>
      <div class="image-wrapper">
        <img class="logo-image" :src="require('@/assets/logo-vertical-yellow.svg')"/>
      </div>
    </div>
    <div class="transactions">
      <div class="transaction" v-for="(t, index) of storyTransactions" :key="index">
        <div class="business-name">{{ t.businessName }}</div>
        <blurred-animated-number v-if="isAnimated" class="amount" @timeout-finished="(value) => updateSum(value, index)"
                                 :value="t.billingAmount" :delay="index * REVEAL_DELAY" :start-timout="startAnimation"/>
        <div v-else class="amount">{{ formatAmountWithFractionAndCurrency(t.billingAmount) }}</div>
      </div>
    </div>
    <separator/>
    <div class="summary-line">
      <div class="prefix">סה״כ</div>
      <div class="amount">
        <animated-number :value="transactionAmountSum" :formatter="formatAmountWithFractionAndCurrency"/>
      </div>
    </div>
  </div>
</template>

<script>
import TransactionDisplayUtils
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/TransactionDisplayUtils';
import BaseUI from '@riseupil/base-ui';
import BlurredAnimatedNumber from '@/base-components/BlurredAnimatedNumber';
import _ from 'lodash';

export default {
  name: 'TransactionStoryCore',
  mixins: [TransactionDisplayUtils],
  components: {
    AnimatedNumber: BaseUI.AnimatedNumber,
    BlurredAnimatedNumber,
    Separator: BaseUI.Separator,
  },
  props: {
    startAnimation: {
      type: Boolean,
      default: false,
    },
    isAnimated: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    titlePrefix: {
      type: String,
    },
    storyTransactions: {
      type: Array,
    },
  },
  data() {
    return {
      transactionSum: 0,
      REVEAL_DELAY: 750,
    };
  },
  computed: {
    transactionAmountSum() {
      return this.isAnimated ? this.transactionSum : this.selectedSum;
    },
    selectedSum() {
      return _.sumBy(this.storyTransactions, t => t.billingAmount);
    },
  },
  methods: {
    updateSum(value, index) {
      this.transactionSum += value;
      if (index === this.storyTransactions.length - 1) {
        this.$emit('finished-revealing-amounts');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../../../scss/mixins';

.transaction-story-core {
  display: flex;
  flex-direction: column;
  height: 100%;
  @include responsive-padding;
  @include responsive-padding-top-bottom;
  background-color: $riseup_white;
  border-radius: 16px;
  .top {
    display: flex;
    .text-wrapper {
      text-align: right;
      flex-grow: 1;
      .title {
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 24px;
      }
      .title-prefix {
        font-size: 18px;
        color: $riseup_blue;
        margin-bottom: 8px;
      }
    }

    .image-wrapper {
      width: 60px;
      position: relative;
      .logo-image {
        position: absolute;
        top: -5px;
        left: 0;
        height: 43px;
      }
    }
  }
  .transactions {
    flex-grow: 1;
    min-height: 50px;
    margin-bottom: 16px;
    overflow: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    .transaction {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      line-height: 36px;
      .business-name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .amount {
        width: 70px;
        text-align: left;
        flex-shrink: 0;
      }
    }
  }
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    display: none;
  }
  .summary-line {
    display: flex;
    justify-content: space-between;
    padding-top: 24px;
    color: $riseup_black;
    font-size: 18px;
    font-weight: bold;
    .amount {
      font-size: 24px;
    }
  }
}
</style>
