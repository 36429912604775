<template>
  <div class="signup-or-redirect">
    <slot :onClick="onClick"></slot>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Segment from '@/Segment';
import router from '@/router';

export default {
  name: 'ViewerSignupOrRedirect',
  props: {
    routerPath: {
      type: String,
      default: '/sr',
    },
    segmentExtraProps: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState('sharePage', ['shareType', 'isLoggedInCustomer']),
    ...mapGetters('sharePage', ['analyticsData']),
    segmentData() {
      return {
        ...this.analyticsData,
        ...this.segmentExtraProps,
      };
    },
    utmParamsQuery() {
      return { utm_source: 'share', utm_medium: 'share', utm_campaign: this.shareType };
    },
  },
  methods: {
    ...mapActions('sharePage', ['sendSharerAnalytics']),
    async onClick() {
      if (this.isLoggedInCustomer) {
        this.redirectToCashflow();
      } else {
        this.redirectToJoinLink();
      }
    },
    redirectToJoinLink() {
      Segment.trackUserInteraction('SharePageSignupClicked', this.segmentData);
      this.sendSharerAnalytics('Sharer_SharePageSignupClicked');
      router.push({ path: '/signup', query: { ...this.utmParamsQuery, ...this.$route.query } });
    },
    redirectToCashflow() {
      Segment.trackUserInteraction('SharePageToCashflowClicked', this.segmentData);
      this.sendSharerAnalytics('Sharer_SharePageToCashflowClicked');
      router.push({ path: this.routerPath, query: this.$route.query });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
