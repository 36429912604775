<template>
  <share-button class="share-eoy" :is-share-link="true" :share-name="shareType" :share-data="shareData" v-on:click.stop
                :share-type="shareType" :share-component="shareComponent" :share-component-props="shareComponentProps" :show-popup="false"
                :segment-extra-props="segmentProps" :share-text="shareText" v-slot="slotProps">
    <riseup-button class="share-button" :title="buttonTitle" :variant="'primary'" :action="(e) => shareClicked(e, slotProps.onClick)"
      :loading="slotProps.loading" :disabled="slotProps.loading"/>
  </share-button>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import sharePageConsts from '@/pages/responsive-pages/non-authenticated/share/sharePageConsts';
import PageViewerAsset from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/PageViewerAsset';
import ShareButton from '@/base-components/share/ShareButton';
import { mapGetters } from 'vuex';
import Segment from '@/Segment';

export default {
  name: 'ShareEoy',
  components: {
    RiseupButton: BaseUI.RiseupButton,
    ShareButton,
  },
  computed: {
    ...mapGetters('story', ['name', 'viewerPages', 'pages']),
    shareType() {
      return sharePageConsts.SHARE_TYPES.STORY;
    },
    shareComponent() {
      return PageViewerAsset;
    },
    shareData() {
      return { name: this.name, pages: this.viewerPages };
    },
    shareComponentProps() {
      // for the preview we want a screenshot of the first page of the story
      return { pageContent: this.pages[0].content };
    },
    shareText() {
      return 'רוצה לראות את סיכום השנה שלי ברייזאפ?';
    },
    buttonTitle() {
      return 'לשיתוף הסיכום השנתי שלי';
    },
    segmentProps() {
      return {
        storyName: this.name,
        pageName: 'share',
      };
    },
  },
  methods: {
    async shareClicked(e, onClick) {
      Segment.trackUserGot('EoyShareClicked', this.segmentProps);
      e.stopPropagation();
      await onClick();
    },
  },
};
</script>

<style lang="scss" scoped>
.share-eoy {
  position: absolute;
  display: flex;
  .share-button {
    width: 100%;
    height: 100%;
    border-radius: 154px;
  }
}
</style>
