<template>
  <viewer-signup-or-redirect v-slot="slotProps" :router-path="'/st/eoy2023'" :segment-extra-props="segmentProps">
    <riseup-button class="signup-button" :title="'קדימה!'" :variant="'primary'" :action="slotProps.onClick"/>
  </viewer-signup-or-redirect>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import ViewerSignupOrRedirect
  from '@/pages/responsive-pages/authenticated/navigation-pages/mid-month/common/ViewerSignupOrRedirect';
import { mapState } from 'vuex';

export default {
  name: 'SignupOrRedirect',
  components: {
    RiseupButton: BaseUI.RiseupButton,
    ViewerSignupOrRedirect,
  },
  computed: {
    ...mapState('sharePage', ['shareData']),
    segmentProps() {
      return {
        storyName: this.shareData.name,
        isSinglePageShare: this.shareData.pages.length <= 2,
        isFooterButton: false,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.signup-or-redirect {
  position: absolute;
  display: flex;
  .signup-button {
    width: 100%;
    height: 100%;
    border-radius: 154px;
  }
}
</style>
