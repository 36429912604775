<template>
  <transition name="bottom-sheet">
    <div class="share-page-bottom-sheet" v-show="showBottomSheet">
      <div class="top" v-show="!showOnlyCta">
        <div class="collapse-wrapper" v-on:click="collapseText">
          <img :src="require('@/assets/share-popup/expand_more.svg')">
        </div>
        <div class="text-wrapper">
          <div class="title">{{ title }}</div>
          <div class="subtitle">{{ subtitle }}</div>
        </div>
      </div>
      <div class="button-wrapper">
        <riseup-button v-if="isLoggedInCustomer" :size="'slim'" class="join-button" :action="redirectToCashflow" :title="'לתזרים'"></riseup-button>
        <riseup-button v-else :size="'slim'" class="join-button" :action="redirectToJoinLink" :title="'להצטרפות'"></riseup-button>

      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import router from '@/router';
import Segment from '@/Segment';

export default {
  name: 'SharedPageBottomSheet',
  components: {
    RiseupButton: BaseUI.RiseupButton,
  },
  data() {
    return {
      showOnlyCta: false,
    };
  },
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    showBottomSheet: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('sharePage', ['shareType', 'sharerCustomerId', 'isLoggedInCustomer', 'sharePageId']),
    ...mapGetters('sharePage', ['analyticsData']),
  },
  methods: {
    ...mapActions('sharePage', ['sendSharerAnalytics']),
    collapseText() {
      Segment.trackUserInteraction('SharePagCtaCollapse', this.analyticsData);
      this.showOnlyCta = true;
    },
    redirectToJoinLink() {
      Segment.trackUserInteraction('SharePageSignupClicked', this.analyticsData);
      this.sendSharerAnalytics('Sharer_SharePageSignupClicked');
      router.push({ path: '/signup/details', query: { utm_source: 'share', utm_medium: 'share', utm_campaign: this.shareType } });
    },
    redirectToCashflow() {
      Segment.trackUserInteraction('SharePageToCashflowClicked', this.analyticsData);
      this.sendSharerAnalytics('Sharer_SharePageToCashflowClicked');
      router.push({ path: '/sr', query: this.$route.query });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../../scss/mixins';

.bottom-sheet-enter-active, .bottom-sheet-leave-active {
  transition: all 0.5s;
}
.bottom-sheet-enter, .bottom-sheet-leave-to {
  transform: translateY(100%);
}

.bottom-sheet-leave, .bottom-sheet-enter-to {
  transform: translateY(0);
}

.share-page-bottom-sheet {
  @include responsive-padding;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 24px;
  background-color: $riseup_white;
  align-items: center;
  width: 100%;
  box-shadow: 0px -2px 8px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px 8px 0px 0px;
  .collapse-wrapper {
    color: $riseup_black;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }
  .text-wrapper {
    margin-bottom: 32px;
    margin-top: 30px;
    font-family: SimplerPro;
    line-height: 20px;
    color: $riseup_black;
    text-align: center;
    .title {
      margin-bottom: 16px;
      font-weight: bold;
      font-size: 24px;
    }
    .subtitle {
      font-size: 18px;
    }
  }
  .button-wrapper {
    background-color: $riseup_white;
    width: 100%;
    .join-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }
}
</style>
