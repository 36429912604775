<template>
  <div class="page-viewer-asset">
    <page-viewer class="page" :page-content="pageContent" :share-mode="true"></page-viewer>
    <img class="share-logo" src="@/assets/mid-month/share-logo.svg" />
  </div>
</template>

<script>
import storyLib from '@riseupil/story-lib';

export default {
  name: 'PageViewerAsset',
  components: {
    PageViewer: storyLib.PageViewer,
  },
  props: {
    pageContent: {
      type: Object,
      required: true,
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.page-viewer-asset {
  position: relative;
  width: 430px;
  height: 768px;
  background-color: $riseup_black;

  .share-logo {
    position: absolute;
    bottom: 23px;
    left: 0;
    right: 0;
    z-index: 9999;
    margin: 0 auto;
  }
}

</style>
